import React, { Component } from 'react';
import PieChartOffline from './PieChartOffline.js';


class DashOffline extends Component {
    constructor(props){
        super(props)
            
        this.state = {
            offlineDevices: props.devices,
            offlineCount: props.count,
            group: props.group,
            onlineCount: props.online
        }
    }
    modTimeStamp(time){
        let formatted = ''
        let tempTimestamp = time
        if(this.timeZone === 'America/Chicago'){
            let year = tempTimestamp.slice(0,4);
            let month = tempTimestamp.slice(5,7);
            let newMonth = Number(month);
            let day = tempTimestamp.slice(8,10);
            let newDay = Number(day);
            let hour = tempTimestamp.slice(11,13);
            let newHour = Number(hour)
            let minute = tempTimestamp.slice(14,16);
            let amPm = "am";
            if(newHour === 12){
                amPm = "pm"
            };
            if(newHour > 12){
                newHour = newHour - 12
                amPm = "pm"
            };
            if(hour === "00"){
                newHour = "12"
            };
            formatted = newMonth + "/" + newDay + "/" + year + " " + newHour + ":" + minute + amPm;
        } else {
            let dateObject = new Date(tempTimestamp) 
            let changedStamp = new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'long', timeZone: this.timeZone }).format(dateObject)
            let monthZone = changedStamp.slice(3,5)
            let dayZone = changedStamp.slice(0,2)
            let yearZone = changedStamp.slice(6,10)
            let hourZone = changedStamp.slice(12,14)
            let newHourZone = Number(hourZone)
            let minuteZone = changedStamp.slice(15,17)

            let amPm = "am"
            if(newHourZone === 12){
                amPm = "pm"
            };
            if(newHourZone > 12){
                hourZone = newHourZone - 12
                amPm = "pm"
            };
            if(hourZone === "00"){
                hourZone = "12"
            };
            formatted = monthZone + "/" + dayZone + "/" + yearZone + " " + hourZone + ":" + minuteZone + amPm;
        }   
        return(
          <div>{formatted}</div>
        );
      };

    async UNSAFE_componentWillReceiveProps(nextProps) {
        let devicesFound = nextProps.devices
        let deviceCount = nextProps.count
        let groupCaught = nextProps.group
        let deviceOnlineCount = nextProps.online

        this.setState({
            offlineDevices: devicesFound,
            offlineCount: deviceCount,
            group: groupCaught,
            onlineCount: deviceOnlineCount

        })
    }

    render(){
        return(
            <div>
                <div className='One-space'/>
                
                <div >
                    {
                        <div>                          
                            {this.state.offlineCount === 0 &&
                                <div className='font-30-60' style={{color: '#529857', fontWeight: 'bold'}}>{this.state.offlineCount}</div>
                            }
                            {this.state.offlineCount !== 0 &&
                                <div className='font-30-60' style={{color: '#d42d0a', fontWeight: 'bold'}}>{this.state.offlineCount}</div>
                            }
                            {this.state.offlineCount !== 1 &&
                                <div className='font-15-30' style={{color: 'white'}}>devices are offline</div>
                            }
                            {this.state.offlineCount === 1 &&
                                <div className='font-15-30' style={{color: 'white'}}>device is offline</div>
                            }                               
                        </div>
                    }
                </div>
                <PieChartOffline 
                    offline={this.state.offlineCount}
                    online={this.state.onlineCount}
                />
                {
                    this.state.offlineDevices.map((device, index) => (
                        <div key={index}>
                            <div className='Half-space'/>
                            {index <= 14 &&
                                <div >
                                    {this.state.group !== 'multiloc' &&
                                        <div className='font-20-40' style={{color: '#8c8b8b', fontWeight: 'bold'}}>{device.organization} - {device.nickname}</div>
                                    }
                                    {this.state.group === 'multiloc' &&
                                        <div className='font-20-40' style={{color: '#8c8b8b', fontWeight: 'bold'}}>{device.nickname}</div>
                                    }
                                    <div className='font-15-30' style={{color: 'white', fontWeight: 'bold'}}>{this.modTimeStamp(device.timestamp)}</div>                                                            
                                </div>
                            }
                            {index === 15 &&
                                <div>
                                    <div className='Half-space'/>
                                    <span className='font-15-30' style={{color: '#d42d0a', fontWeight: 'bold'}}>more</span>
                                </div>  
                            }

                        </div>
                    ))
                }
            </div>
        )
    }
}

export default DashOffline