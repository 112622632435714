import React, { Component } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

class PieChartOffline extends Component {

    constructor(props){
        super(props)
        let offlineCount = props.offline
        let onlineCount = props.online

        let finalData = [{name: "offline", value: offlineCount, color: "#d42d0a"},{name: 'online', value: onlineCount, color: "#529857"}]

            
        this.state = {
            data: finalData
        }
    }

    async UNSAFE_componentWillReceiveProps(nextProps) {
        let offlineCount = nextProps.offline
        let onlineCount = nextProps.online

        let finalData = [{name: "offline", value: offlineCount, color: "#d42d0a"},{name: 'online', value: onlineCount, color: "#529857"}]


        this.setState({
            data: finalData

        })
    }
        


    render(){
        return(
            <div className='pie-height'>
                <ResponsiveContainer>
                <PieChart>
                    <Pie data={this.state.data} dataKey="value" nameKey="name" cx="50%" cy="50%">
                    {this.state.data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                    </Pie>
                </PieChart>
                </ResponsiveContainer>

            </div>
        )
    }
    
}

export default PieChartOffline