import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import NowSettings from '../components/NowSettings';
import NowOrgSettings from '../components/NowOrgSettings';

class MainSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      group: '',
      userName: '',
    };
  }

  signOut = () => {
    Auth.signOut()
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
    this.props.history.push('/SignIn');
  };

  async componentDidMount() {
    Auth.currentAuthenticatedUser({
      bypassCache: false,
    })
      .then((user) => {
        this.loggedIn = true;
      })
      .catch((error) => {
        if (error === 'not authenticated') {
          this.props.history.push('/SignIn');
        }
      });

    let user = await Auth.currentAuthenticatedUser();
    let userGroupGet =
      user.signInUserSession.accessToken.payload['cognito:groups'];

    this.setState({
      group: userGroupGet[0],
      userName: user.username,
    });
  }

  render() {
    var backIcon = require('../Back-01.png');

    return (
      <div className='App'>
        {
          <div className='Nav-header'>
            <Link style={{ textDecoration: 'none' }} to='/'>
              <button className='Back-wrap'>
                <img className='Back-button' src={backIcon} alt='back' />
              </button>
            </Link>
            <button onClick={this.signOut} className='Settings-button'>
              signout
            </button>
          </div>
        }
        {this.state.group === 'enerstar' && (
          <NowSettings
            group={this.state.group}
            userName={this.state.userName}
          />
        )}
        {this.state.group === 'multiloc' && (
          <NowOrgSettings
            group={this.state.group}
            userName={this.state.userName}
          />
        )}
        <div className='Icon-orderlabel'>version 4.1.3</div>
        <div className='Two-space' />
      </div>
    );
  }
}

export default MainSettings;
