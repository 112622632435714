import React, { Component } from 'react';
import GraphAlarms from './GraphAlarms.js';
import { API } from 'aws-amplify';

class DashAlarms extends Component {
    constructor(props){
        super(props)

        this.state = {
            alarmsArray: props.data,
            readingCount: props.count,
            level: props.group,
            serial: '',
            data: [],
            group: '',
            name: '',
            alarm: '',
            alarmLine: '',
            currentReading: '',
            networkError: props.networkError
        }


        if(props.count === undefined){
            console.log('empty alarms caught')
        } else {
            this.fetchGraphData()
        }

        this.count = 0

        this.heldGraphData = []
        this.heldIndex = 0

        this.refreshGraph = this.refreshGraph.bind(this)
        this.fetchGraphData = this.fetchGraphData.bind(this)
    }

    async refreshGraph(){
        if(this.state.readingCount === 0 ){
            return
        }
        
        try{
            this.setState({
                serial: this.heldGraphData[this.heldIndex].serial,
                data: this.heldGraphData[this.heldIndex].data,
                group: this.heldGraphData[this.heldIndex].group,
                name: this.heldGraphData[this.heldIndex].name,
                alarm: this.heldGraphData[this.heldIndex].alarm,
                alarmLine: this.heldGraphData[this.heldIndex].alarmLine,
                currentReading: this.heldGraphData[this.heldIndex].currentReading
            })
        } catch(error)  {
            console.log('error setting new graph data')
            //console.log(error)
        }

        
        if(this.heldIndex >= this.heldGraphData.length - 1){
            this.heldIndex = 0
        } else {
            this.heldIndex++
        }
        
 
    }

    async fetchGraphData(){
        this.count = 0

        if(this.state.readingCount === 0 ){
            return
        }

        let returnArray = [];
        let result = {};
      
        const getDeviceData = async() => {
          await Promise.all(this.state.alarmsArray.map(async (device) => {
            let queryName = '/items/'+device.serial

            try{
                result = await API.get('dataqueryapi', queryName);
                
            } catch(error){
                console.log(error)
                if(error.message === 'Network Error'){
                    return
                } else {
                    return
                }
            }
            try{
                returnArray.push(result)
              }catch(error){
                console.log(error)
              }
            return result
          }))
      
        }
        await getDeviceData()
        
        let graphArray = []

        this.state.alarmsArray.forEach( unit => {
            returnArray.forEach( data => {
                if(unit.serial === data[0].device)                 
                    unit.alarms.forEach( async (alarm) =>{
                        let modLine = alarm.info
                        let cutLine = modLine.substring(6)
                        let numLine = Number(cutLine)
                      
                        if(alarm.reading !== 'no input board'){
                            graphArray.push({'serial': unit.serial, 'group': unit.group, 'name': unit.title, 'alarm': alarm.name, 'alarmLine': numLine, 'data': data, 'currentReading': alarm.reading})
                        }                           
                    })
            })
        })

        this.heldGraphData = []
        this.heldGraphData = graphArray

        this.refreshGraph()


    }


    async UNSAFE_componentWillReceiveProps(nextProps) {
        let alarmsArray = nextProps.data
        let readingCount = nextProps.count
        let receivedLevel = nextProps.group
        let receivedNetworkError = nextProps.networkError

        this.count++

        if(this.count >=4){
            this.fetchGraphData()
        }

        this.setState({
            alarmsArray: alarmsArray,
            readingCount: readingCount,
            level: receivedLevel,
            networkError: receivedNetworkError
        })

    }

    UNSAFE_componentWillUnmount() {
        //cant perform react state update on unmounted component
        clearInterval(this.interval);
            this.setState = (state,callback)=>{
                return;
            };
    }

    async componentDidMount(){

        this.interval = setInterval(this.refreshGraph, 6000);
    }

    render(){
        var logo = require('../Nowledge-Logo_ALL-WHT.png');

        return(
            <div>
                
                <div>
                    {
                        <div>
                            <img className='dash-logo' src={logo} alt="nowledge" />
                            {this.state.networkError &&
                                <div className='dash-online' style={{fontWeight: 'bold', color: '#d42d0a'}}>offline</div> 
                            }
                            {!this.state.networkError &&
                                <div className='dash-online' style={{fontWeight: 'bold', color: '#529857'}}>online</div>
                            }
                            <div className='Half-space'/>                              
                            {this.state.readingCount === 0 &&
                                <span className='font-35-70' style={{color: '#529857', fontWeight: 'bold', marginRight: '7.5%'}}>{this.state.readingCount}</span>
                            }
                            {this.state.readingCount !== 0 &&
                                <span className='font-35-70' style={{color: '#d42d0a', fontWeight: 'bold', marginRight: '7.5%'}}>{this.state.readingCount}</span>
                            }
                            {this.state.readingCount !== 1 &&
                                <div className='font-15-30' style={{color: 'white'}}>readings out of range</div>
                            }
                            {this.state.readingCount === 1 &&
                                <div className='font-15-30' style={{color: 'white'}}>reading out of range</div> 
                            }
                            <div className='Half-space'/>                                                 
                        </div>
                    }
                </div>
                {this.state.readingCount !== 0 &&
                    <GraphAlarms
                        data={this.state.data}
                        alarm={this.state.alarm}
                        level={this.state.level}
                        group={this.state.group}
                        name={this.state.name}
                        alarmLine={this.state.alarmLine}
                        currentReading={this.state.currentReading}
                    />  
                }            
                <div className='alarm-box-wrapper'>
                    {
                        this.state.alarmsArray.map((alarms, index) => (
                            <div key={index}>
                                {index <= 14 &&
                                    <div className='alarm-box'>
                                        {this.state.level !== 'multiloc' &&
                                            <div>
                                                <div className='font-15-30' style={{color: '#8c8b8b', fontWeight: 'bold'}}>{alarms.group}</div>
                                                <div className='font-15-30' style={{color: '#8c8b8b', fontWeight: 'bold'}}>{alarms.title}</div>
                                            </div>
                                        }
                                        {this.state.level === 'multiloc' &&
                                            <div className='font-15-30' style={{color: '#8c8b8b', fontWeight: 'bold'}}>{alarms.title}</div>
                                        }
                                        <div className='Half-space'/>
                                        {this.state.index !== index &&
                                            <div>
                                                {
                                                    alarms.alarms.map((alarm, index) => (
                                                        <div key={index}>
                                                            {index <= 3 &&
                                                                <div>
                                                                    <span className='font-15-30' style={{color: 'white', fontWeight: 'bold'}}>{alarm.name.replace(/_/g, ' ')}</span>
                                                                    <span className='font-15-30' style={{color: 'white', fontWeight: 'bold'}}>&nbsp;&nbsp;</span>
                                                                    <span className='font-15-30' style={{color: '#d42d0a', fontWeight: 'bold'}}>{alarm.reading}</span>
                                                                </div>
                                                            }
                                                            {index === 4 &&
                                                                <div>
                                                                    <div className='Half-space'/>
                                                                    <span className='font-15-30' style={{color: '#d42d0a', fontWeight: 'bold'}}>more</span>
                                                                </div>                                           
                                                            }                                               

                                                        </div>
                                                        
                                                    ))
                                                }
                                            </div>            
                                        }                                        
                                    </div>  
                                }

                            </div>
                
                        ))                  
                    }
                </div>

                <div>
                    {this.state.alarmsArray.length > 15 &&                                               
                        <div className='font-15-30' style={{color: '#d42d0a', fontWeight: 'bold'}}>more</div>                                
                    }
                </div>
            </div>
        )
    }
}

export default DashAlarms