import React, { Component } from 'react';
import {LineChart, ReferenceLine, Line, XAxis, YAxis, ResponsiveContainer} from 'recharts';

class GraphAlarms extends Component {
    constructor(props){
        super(props);

        this.state = {
            data: [],
            alarm: ''
        }
    }

    async UNSAFE_componentWillReceiveProps(nextProps) {

        let receivedLevel = nextProps.level
        let dataReceived = nextProps.data
        let alarmReceived = nextProps.alarm
        let alarmLineReceived = nextProps.alarmLine
        let groupReceived = nextProps.group
        let nameReceived = nextProps.name
        let currentReading = nextProps.currentReading

        this.setState({
            data: dataReceived,
            alarm: alarmReceived,
            level: receivedLevel,
            alarmLine: alarmLineReceived,
            group: groupReceived,
            name: nameReceived,
            currentReading: currentReading
        })

    }


    render(){
        return(
            <div>
                {this.state.level !== 'multiloc' &&
                    <div className='font-20-40' style={{color: '#8c8b8b', fontWeight: 'bold'}}>{this.state.group} - {this.state.name}</div>
                }
                {this.state.level === 'multiloc' &&
                    <div className='font-20-40' style={{color: '#8c8b8b', fontWeight: 'bold'}}>{this.state.name}</div>
                }
                
                <div>
                    <span className='font-15-30' style={{color: 'white', fontWeight: 'bold'}}>{this.state.alarm.replace(/_/g, ' ')}</span>
                    <span className='font-15-30' style={{color: 'white', fontWeight: 'bold'}}>:&nbsp;&nbsp;</span>
                    <span className='font-15-30' style={{color: '#d42d0a', fontWeight: 'bold'}}>{this.state.currentReading}</span>
                </div>
                {
                    <div className="graphs-dash">                      
                            <ResponsiveContainer width="100%" height="100%">
                                <LineChart data={this.state.data} margin={{top: 15, right: 0, left: 0, bottom: 15}}>
                                    <XAxis dataKey="timestamps" hide={true} axisLine={true} reversed={true}/>
                                    <YAxis scale="auto" axisLine={false} domain={['dataMin', 'dataMax']}/>
                                    <ReferenceLine y={this.state.alarmLine} ifOverflow='extendDomain' stroke="#d42d0a" strokeWidth={4}/>
                                    <Line type="monotone" dataKey={this.state.alarm} stroke="#529857" dot={false} strokeWidth={6} connectNulls={true} isAnimationActive={true}/>
                                </LineChart>
                            </ResponsiveContainer>
                        
                    </div>
                }    
            </div>
        )
    }

}



export default GraphAlarms
