import React, { Component } from 'react';
import {S3Image} from 'aws-amplify-react';

class DashNotes extends Component {
    constructor(props){
        super(props)

        let noNotesFound = false
        if(props.notes.length === 0){
            noNotesFound = true
        }
            
        this.state = {
            notes: props.notes,
            noNotes: noNotesFound,
            group: props.group
        }
    }

    renderNoteTime(time){
        let utcDate = time;
        let localDate = new Date(utcDate);   
        let date4 = (localDate.getMonth()+1) + "/" + localDate.getDate() + "/" + localDate.getFullYear()    
        let hour = localDate.getHours()
        let minute = localDate.getMinutes();
    
        if(minute < 10){
            minute = ('0' + minute).slice(-2)
        };   
        let amPm = "am";
        if(hour === 12){
            amPm = "pm"
        };
        if(hour > 12){
            hour = hour - 12
            amPm = "pm"
        };
        if(hour === 0){
            hour = 12
        };
    
        let time1 = hour + ":" + minute + amPm
        let dateTime1 = date4 + " " + time1;
        return(
            dateTime1
        );
    };

    async UNSAFE_componentWillReceiveProps(nextProps) {
        let receivedNotes = nextProps.notes
        let receivedGroup = nextProps.group

        let noNotesFound = this.state.noNotes

        if(receivedNotes.length === 0){
            noNotesFound = true
        } else {
            noNotesFound = false
        }

        this.setState({
            notes: receivedNotes,
            noNotes: noNotesFound,
            group: receivedGroup
        })
    }

    render(){
        return(
            <div>
                <div className='One-space'/>
                {!this.state.noNotes &&                                      
                    this.state.notes.map((notes, index) => (
                        <div key={index}>

                            <div className='One-space'/>
                            {!this.state.noNotes &&
                                <div className="dash-notes-wrapper">                                   
                                    {this.state.group !== 'multiloc' &&
                                        <div className='font-20-40' style={{color: '#8c8b8b', fontWeight: 'bold'}}>{notes.group} - {notes.title}</div>
                                    }
                                    {this.state.group === 'multiloc' &&
                                        <div className='font-20-40' style={{color: '#8c8b8b', fontWeight: 'bold'}}>{notes.title}</div>
                                    }
                                    <div className='font-20-40' style={{color: 'white', fontWeight: 'bold'}}>{notes.content.replace(/<br\s*\\?>/g, "\r\n").split('\n').map( (line, i) => <div key={'x'+i}>{line}</div> )}</div>
                                    
                                    { notes.image !== undefined && notes.image !== null && notes.image !== 'null' &&
                                        <S3Image imgKey={notes.image} theme={{ photoImg: { width: '50%' } }}/>
                                    }
                                    <div className='font-15-30' style={{color: '#529857', fontWeight: 'bold'}}>{this.renderNoteTime(notes.timestamp)} - {notes.author}</div>
                                    
                                </div>
                            }
                        </div>
                    ))                   
                }
                {this.state.noNotes &&
                    <div>
                        <div className='Two-space'/>
                        <div className="dash-notes-wrapper">                                   
                            {this.state.group === 'multiloc' &&
                                <div className='font-20-40' style={{color: '#8c8b8b', fontWeight: 'bold'}}>Notes</div>
                            }
                            <div className='font-20-40' style={{color: 'white', fontWeight: 'bold'}}>Welcome to Nowledge Dashboard. Notes posted within your organization will show up here.</div>
                            
                            <div className='font-15-30' style={{color: '#529857', fontWeight: 'bold'}}>Nowledge Team</div>                    
                        </div>
                    </div>

                }
            </div>
        )
    }
}

export default DashNotes